import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const store = createStore({
  state: {
	// 処理タイプ
	procType: '2',

	// 処理タイプ別定数
	KOKAN: '2',
	TEKKYO: '3',

	memberId: '',
	contCd: '',
	rentNo: '',

  },
  mutations: {
	setProcType(state, payload) {
		state.procType = payload.procType;
	},
	setMemberId(state, payload){
		state.memberId = payload.memberId;
	},
	setContCd(state, payload){
		state.contCd = payload.contCd;
	},
	setRentNo(state, payload){
		state.rentNo = payload.rentNo;
	},
  },
  plugins: [
	createPersistedState({
		key: 'vuex-session',
		storage: window.sessionStorage
	})
  ]
})

export default store