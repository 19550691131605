<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">MENU</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <!-- List Inset Lines -->
          <div class="menubtn" style="margin-top: 30px;">
              <span class="menu-btn" @click="clickChange"><img :src="btn1" width="300" height="173" alt="交換依頼"/></span>
          </div>
          <div class="menubtn">
              <span class="menu-btn" @click="clickTekkyo"><img :src="btn2" width="300" height="104" alt="撤去依頼"/></span>
          </div>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonContent, IonPage, IonCardTitle, IonCard, alertController } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Menu',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
  },
  data(){
      return {
        deviceCd:'',
        tel:'',
        accessDate:'',
        btn1: require('@/assets/img/genba_btn1.png'),
        btn2: require('@/assets/img/genba_btn2.png'),
      };
  },
  mounted() {
  },
  methods: {
    // QRコード読取画面へ遷移
    clickChange: function () {
      sessionStorage.setItem('title', '交換依頼');
      sessionStorage.setItem('status', 2);
      this.$store.commit('setProcType', { procType: this.$store.state.KOKAN });
      this.$router.push({ name: 'Change' });
    },
    clickTekkyo: async function () {
      const alert = await alertController
        .create({
          cssClass: 'my-custom-class',
          header: '確認',
          message: '撤去依頼作業に進んでもよろしいでしょうか。',
          buttons: [
            {
              text: 'いいえ',
              role: 'cancel',
              cssClass: 'secondary',
              id: 'cancel-button',
              handler: blah => {
                console.log('Confirm Cancel:', blah)
              },
            },
            {
              text: 'はい',
              id: 'confirm-button',
              handler: () => {
                console.log('Confirm Okay')
                sessionStorage.setItem('title', '撤去依頼');
                sessionStorage.setItem('status', 3);
                this.$store.commit('setProcType', { procType: this.$store.state.TEKKYO });
                this.$router.push({ name: 'Change' });
              },
            },
          ],
        });
      return alert.present();
    }
  } 
});
</script>

<style scoped>
.menubtn{
  margin:20px 0;
  text-align: center;
}

.menu-btn {
  cursor: pointer;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  flex: 0 0 auto;
  font-weight: 500;
  letter-spacing: .0892857143em;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0.0892857143em;
  text-transform: uppercase;
  transition-duration: .28s;
  transition-property: box-shadow,transform,opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  filter: drop-shadow(2.5px 2.5px 0 rgb(196, 196, 196));
}
.menu-btn:hover {
  cursor: pointer;
    opacity: 0.8;
}
.menu-btn:active {
  filter: drop-shadow(0.5px 0.5px 0 rgb(196, 196, 196));
  transform: translate(2px, 2px) scale3d(1, 1, 1);
}
img{
  image-rendering: -webkit-optimize-contrast;
  -webkit-backface-visibility: hidden;
}
#back {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
#tokui-text {
  font-size:20px;
  text-decoration: underline;
  color: rgb(25, 148, 170);
}
</style>