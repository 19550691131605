import { createRouter, createWebHistory } from '@ionic/vue-router';
import Login from '../views/Login.vue';
import Forgot from '../views/Forgot.vue';
import ForgotToroku from '../views/ForgotToroku.vue';
import Phonenum from '../views/Phonenum.vue';
import Toroku from '../views/Toroku.vue';
import Menu from '../views/Menu.vue';
import Change from '../views/Change.vue';
import InpCont from '../views/InpCont.vue';
import Kakutei from '../views/Kakutei.vue';
import Kanryo from '../views/Kanryo.vue';
import Photos from '../views/Photos.vue';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: Forgot
  },
  {
    path: '/forgottoroku',
    name: 'ForgotToroku',
    component: ForgotToroku
  },
  {
    path: '/signup',
    name: 'Phonenum',
    component: Phonenum
  },
  {
    path: '/toroku',
    name: 'Toroku',
    component: Toroku
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu 
  },
  {
    path: '/change',
    name: 'Change',
    component: Change
  },
  {
    path: '/inpcont',
    name: 'InpCont',
    component: InpCont
  },
  {
    path: '/kakutei',
    name: 'Kakutei',
    component: Kakutei
  },
  {
    path: '/kanryo',
    name: 'Kanryo',
    component: Kanryo 
  },
  {
    path: '/photos',
    name: 'Photos',
    component: Photos 
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next)=>{
  if(to.name !== 'Login' && to.name !== 'Phonenum' && to.name !== 'Toroku' && localStorage.getItem('genba_device_cd') == null){
    next({ name: 'Login'})
    console.log(to.name);
  } 
  else next()
})

export default router
