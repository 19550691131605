<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="medium">
          <ion-card-title class="ion-text-center" size="large">システム認証登録</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <!-- List Inset Lines -->
          <ion-item class="ion-text-center">認証コードを再送付いたしました。</ion-item>
          <ion-button style="margin:1em 0.6em 1em 0.6em" id="kakutei" color="primary" expand="block" @click="clickNext">次へ</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import {
  IonContent, IonPage, IonCardTitle, IonCard,
  IonButton, IonItem, IonCardHeader } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ForgotToroku',
  components: {
    IonContent, IonPage, IonCardTitle, IonCard, IonButton,
    IonItem, IonCardHeader
  },
  data(){
      return {
      };
  },
  methods: {
    // メニュー画面に移動
    clickNext(){
      this.$router.push("/Login");
    }
  }
});
</script>

<style scoped>
#kakutei {
  margin:20px;
}
#back {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
#tokui-text {
  font-size:20px;
  text-decoration: underline;
  color: rgb(25, 148, 170);
}
</style>