<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">{{ title }}</ion-card-title>
        </ion-card-header>
      <ion-card-content>
        <!-- List Inset Lines -->
        <ion-list lines="inset">
          <ion-item>
            <div class="display-block">
              <ion-label>コンテナ管理会社</ion-label>
              <ion-note slot="end">{{corpName}}</ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="display-block">
              <ion-label>現場名</ion-label>
              <ion-note slot="end">{{genbaName}}</ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="display-block">
              <ion-label>住所</ion-label>
              <ion-note slot="end">{{address}}</ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="display-block">
              <ion-label>コンテナ番号</ion-label>
              <ion-note slot="end">{{contCd}}</ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="display-block">
              <ion-label>設置日</ion-label>
              <ion-note slot="end">{{dateFormat(startDate)}}</ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="display-block">
              <ion-label>廃棄物量</ion-label>
              <ion-note slot="end">
                <select v-model="haiki" style="width:150px">
                  <option value="1"></option>
                  <option value="2">50%</option>
                  <option value="3">80%</option>
                  <option value="4">100%</option>
                  <option value="5">それ以外</option>
                </select>
              </ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="display-block">
              <ion-label>希望回収日</ion-label>
              <ion-note slot="end">
                <ion-icon :icon="calendarOutline" @click="clickCal"></ion-icon>
                <input style="margin-left:5px;" type="date" v-model="kibouDate" />
              </ion-note>
            </div>
          </ion-item>
          <ion-item>
            <div class="display-block">
              <ion-label>備考</ion-label>
              <ion-note slot="end">
                <ion-textarea class="inp-style" v-model="biko" maxlength="400" rows="5"></ion-textarea>
              </ion-note>
            </div>
          </ion-item>
        </ion-list>

        <ion-button v-if="protype!=2" id="kakutei" color="primary" expand="block" @click="clickKakutei">確定</ion-button>
        <ion-button id="back" color="warning" @click="backPage">戻る</ion-button>
      </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<style scoped>
.display-block {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>

<script>

import { IonContent, IonPage, IonCardTitle, IonLabel, IonCard, IonCardHeader, IonCardContent, IonButton, IonList, IonItem, IonNote, IonIcon, IonTextarea } from '@ionic/vue';
import { defineComponent } from 'vue';
import { calendarOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'Kakutei',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonLabel,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonButton,
    IonList,
    IonItem,
    IonNote,
    IonIcon,
    IonTextarea,
  },
  setup(){
    return { calendarOutline }
  },
  data(){
      return {
        title: '',
        memberId:'',
        genbaName:'',
        address:'',
        contCd:'',
        startDate:'',
        haiki: 0,
        rentNo: '',
        kibouDate: '',
        biko:'',
      };
  },
  ionViewDidEnter() {
    this.title = sessionStorage.getItem('title');
    this.setContInfo();

    // 希望回収日に現在日をセット
    const d = new Date();
    this.kibouDate = `${d.getFullYear()}-${String(d.getMonth()+1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;

  },
  methods: {
    // コンテナ情報を画面で引き継ぐ
    setContInfo: function () {
      this.memberId = this.$route.params.memberId;
      this.corpName = this.$route.params.corpName;
      this.genbaName = this.$route.params.genbaName;
      this.address = this.$route.params.address;
      this.startDate = this.$route.params.startDate;
      this.contCd = this.$route.params.contCd;
      this.biko = this.$route.params.biko;

      if(this.genbaName=='null' || !this.genbaName){
        this.genbaName= '';
      }
      if(this.address=='null' || !this.address){
        this.address = '';
      }
    },
    // コンテナ情報更新
    clickKakutei: function () {

      this.axios.put(`genba-change/${this.contCd}`, { member_id: this.memberId, cont_cd: this.contCd, cont_rate: this.haiki, kibou_date: this.kibouDate, status: sessionStorage.getItem('status'), biko: this.biko }).then(res => {
        if(res.data["result"] == "success"){
          this.rentNo = res.data["rent_no"];
          this.axios.post('mail', { mobile_type: 1, member_id: this.memberId, rent_no: this.rentNo, p_type: sessionStorage.getItem('status') });
          this.$store.commit('setMemberId', { memberId: this.memberId });
          this.$store.commit('setContCd', { contCd: this.contCd });
          this.$store.commit('setRentNo', { rentNo: this.rentNo });
          this.$router.push('/kanryo');
        }else{
          alert(res.data["msg"]);
        }
      });
    },
    clickCal: function () {
      console.log(this.$refs.kibouEndDate.$el.childNodes[0]);
      this.$refs.kibouEndDate.$el.childNodes[0].click();
    },
    // 戻る
    backPage: function () {
      this.$router.back();
    },
  }
});
</script>

<style scoped>
#kakutei {
  margin-top: 20px;
  margin-left:20px;
  margin-right:20px;
}
#back {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
#tokui-text {
  font-size:20px;
  text-decoration: underline;
  color: rgb(25, 148, 170);
}
.inp-style {
  border: solid 0.5px;
  border-color: #ddd;
  border-radius: 5px;
}
</style>