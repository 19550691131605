<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="medium">
            <ion-card-title class="ion-text-center" size="xx-large">ログイン</ion-card-title>
        </ion-card-header>
          <ion-list>
              <div style="margin: 1.0em 0em 1.0em 1.5em; max-width:auto;">ご登録時のメールアドレスと、メールに記載されている「認証コード」をご入力ください</div>
          </ion-list>
          <ion-list style="display: flex">
              <ion-input class="txt-disp" v-model="phoneNum" maxlength="15" @input="phoneNum = $event.target.value">電話番号</ion-input>
          </ion-list>
          <ion-text class="err-disp" type="tel" color="danger" v-if="errMsg.phone!=''">{{ errMsg.phone }}</ion-text>
          <ion-list style="display: flex">
              <ion-input class="txt-disp" v-model="autheCode" @input="autheCode = $event.target.value">認証コード</ion-input>
          </ion-list>
          <ion-text class="err-disp" type="email" color="danger" v-if="errMsg.authe!=''">{{ errMsg.authe }}</ion-text>
          <ion-list v-if="errMsg.msg!=''">
            <ion-text class="err-disp" color="danger" style="white-space: pre;">{{ errMsg.msg }}</ion-text>
          </ion-list>
          <ion-footer>
              <ion-button style="margin:1em 0.6em 1em 0.6em" id="kakutei-back" expand="block" color="primary" @click="clickNext()">次へ</ion-button>
              <ion-button style="display: flex;" color="primary" fill="clear" @click="clickForgot()"> 認証コードを忘れた場合はこちら</ion-button>
          </ion-footer>
      </ion-card>
    </ion-content>
  </ion-page>
</template>
<style scoped>
.disp-block {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}
.txt-disp {
    margin: 1em 1em;
    text-align: start;
    text-indent: 10px;
    max-width: 25em;
    min-height: 4em;
    border: 3px solid #ccc;
    border-radius: 4px;
}
.err-disp {
    margin: 0em 0em 0em 2em;
    text-align: center;
    max-width: 25em;
    min-height: 4em;
}
</style>
<script>
import {
    IonContent,
    IonPage,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonList,
    IonInput,
    IonText,
    IonButton,
    IonFooter,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "Login",
    components: {
        IonContent,
        IonPage,
        IonCard,
        IonCardTitle,
        IonCardHeader,
        IonList,
        IonInput,
        IonText,
        IonButton,
        IonFooter,
    },
    data() {
        return {
            authChk: false,
            phoneNum: "",
            autheCode: "",
            errMsg: {
                phone: "",
                authe: "",
                msg: "",
                status: "",
            },
        };
    },
    ionViewDidEnter() {
        // ローカルストレージからデバイスコード取得
        var deviceCd = localStorage.getItem("genba_device_cd");

        // 認証処理
        var accessDate = this.get_now_ymd();
        this.axios
            .post("genba-login", {
                device_cd: deviceCd,
                access_date: accessDate,
            })
            .then((res) => {
                if (res.data == "authrized") {
                    this.$router.push("/menu");
                }
            });
    },
    mounted() {
        // ローカルストレージからデバイスコード取得
        var deviceCd = localStorage.getItem("genba_device_cd");

        // pwaの場合urlのパラメータからデバイスコード取得
        if (deviceCd === null || deviceCd === "") {
            deviceCd = this.$route.query.device;
            localStorage.setItem("genba_device_cd", deviceCd);
        }

        // manifest.jsonの生成
        this.setManifestURL(deviceCd);

        // 認証処理
        var accessDate = this.get_now_ymd();
        this.axios
            .post("genba-login", {
                device_cd: deviceCd,
                access_date: accessDate,
            })
            .then((res) => {
                if (res.data == "authrized") {
                    this.$router.push("/menu");
                } else {
                    this.authChk = true;
                }
            })
            .catch(() => {
                this.authChk = true;
            });
    },
    methods: {
        // 現在の日時を取得
        get_now_ymd: function () {
            var dt = new Date();
            var y = dt.getFullYear();
            var m = ("00" + (dt.getMonth() + 1)).slice(-2);
            var d = ("00" + dt.getDate()).slice(-2);
            var result = y + "/" + m + "/" + d;
            return result;
        },
        // pwa用のmanifestを生成
        setManifestURL: function (token) {
            var baseUrl = "https://" + document.domain;
            var startUrl = token ? baseUrl + "?device=" + token : baseUrl;

            var manifest = {
                name: "Web-Container",
                short_name: "モバイル作業者システム",
                icons: [
                    {
                        src: baseUrl + "/img/icons/sms-icon-bgw-192x192.png",
                        sizes: "192x192",
                        type: "image/png",
                    },
                    {
                        src: baseUrl + "/img/icons/sms-icon-bgw-512x512.png",
                        sizes: "512x512",
                        type: "image/png",
                    },
                    {
                        src: baseUrl + "/img/icons/sms-icon-bgw-192x192.png",
                        sizes: "192x192",
                        type: "image/png",
                        purpose: "maskable",
                    },
                    {
                        src: baseUrl + "/img/icons/sms-icon-bgw-512x512.png",
                        sizes: "512x512",
                        type: "image/png",
                        purpose: "maskable",
                    },
                ],
                start_url: startUrl,
                display: "standalone",
                background_color: "white",
                theme_color: "#4DBA87",
            };

            const stringManifest = JSON.stringify(manifest);
            const blob = new Blob([stringManifest], {
                type: "application/json",
            });
            const manifestURL = URL.createObjectURL(blob);
            document
                .querySelector("#my-manifest")
                .setAttribute("href", manifestURL);
        },
        async clickNext() {
            //エラーコード初期化
            this.errMsg.phone = "";
            this.errMsg.authe = "";
            this.errMsg.msg = "";
            this.errMsg.status = "";

            //変数の空欄チェック
            if (!this.phoneNum) {
                this.errMsg.phone = "電話番号";
            }
            if (!this.autheCode) {
                this.errMsg.authe = "認証コード";
            }

            if (this.errMsg.phone || this.errMsg.authe) {
                const msg = "が記入されておりません。";
                this.errMsg.phone =
                    this.errMsg.phone + (this.errMsg.phone != "" ? msg : "");
                this.errMsg.authe =
                    this.errMsg.authe + (this.errMsg.authe != "" ? msg : "");
                return;
            }

            //電話番号と認証コードでデバイスコードを検索
            const res = await this.axios.post("genba-login-again", {
                phone: this.phoneNum,
                authe_cd: this.autheCode,
                access_date: this.get_now_ymd(),
            });

            //検索結果該当の情報が見つからなかった場合
            if (res.data.status == "1") {
                this.errMsg.msg =
                    "記載された情報が確認できませんでした。\n      もう一度内容をご確認ください。";
                return;
            }
            //デバイスコードをセット
            localStorage.setItem("genba_device_cd", res.data.data.device_cd);

            this.$router.push({ name: "Menu" });
        },
        async clickForgot() {
            this.$router.push({ name: "Forgot" });
        },
    },
});
</script>

<style scoped>
.toroku {
    margin: 20px;
}
</style>