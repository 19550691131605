<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="medium">
            <ion-card-title class="ion-text-center" size="xx-large">認証コード再発行</ion-card-title>
        </ion-card-header>
          <ion-list>
              <div style="margin: 1.0em 2em 1.0em 1.5em; max-width:auto;">
                再発行するモバイル端末の電話番号を入力してください。
                <div style="color:red;">
                入力されたメールアドレス宛に再発行した認証コードをお送りします。
                </div>
              </div>
          </ion-list>
          <ion-list>
            <ion-input class="txt-disp" v-model="phoneNum" maxlength="11" @input="phoneNum = $event.target.value">電話番号</ion-input>
            <ion-text color="danger" class="err-disp" v-show="errMsg!=''">{{ errMsg }}</ion-text>
          </ion-list>
          <ion-footer>
              <ion-button style="margin:1em 0.6em 1em 0.6em" id="kakutei-back" expand="block" color="primary" @click="clickSend()">送信</ion-button>
              <ion-button style="margin:1em 0.6em 1em 0.6em" id="kakutei-back" expand="block" color="warning" @click="back()">もどる</ion-button>
          </ion-footer>
      </ion-card>
    </ion-content>
  </ion-page>
</template>
<style scoped>
.txt-disp{
    margin: 1.0em 0em 0.5em 1.5em;
    text-align: start;
    text-indent: 10px;
    max-width: 25em;
    min-height: 3em;
    border: 1px solid;
    border-radius: 3px;
}
.err-disp{
  margin: 0.0em 0em 0.0em 2.0em;
    text-align: center;
    max-width: 25em;
    min-height: 4em;
}
</style>
<script>
import { IonContent, IonPage,IonCard,IonCardTitle,IonCardHeader,IonList,IonButton,IonFooter,IonInput } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Forgot',
  components: {
    IonContent,IonPage,IonCard,IonCardTitle,IonCardHeader,IonList,
    IonButton,IonFooter,IonInput
  },
  data(){
      return {
        mail:'',
        phoneNum:'',
        errMsg:'',
      }
  },
  methods:{
    async clickSend(){
        this.errMsg=''
        //入力された電話番号、認証コードを登録
        const resForgot = await this.axios.post('genba-forgot', {
            'phone': this.phoneNum,
        })
        if(resForgot.data.status!='0'){
            this.errMsg = resForgot.data.msg
            return
        }
        this.$router.push({ name: 'ForgotToroku'});
        // this.mail=resForgot.data.mail

        // const res = await this.axios.post('genba-toroku', {
        //     'email': this.mail,
        //     'mode':'1'
        // })
        // if(res.data.result == 0){
        //     this.$router.push({ name: 'ForgotToroku'});
        // }
        


    },
    back(){
        this.$router.push({ name: 'Login'});
    }
  }
})
</script>