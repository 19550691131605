<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
            <ion-card-title class="ion-text-center" size="large">写真</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <div class="photo-area">
            <span style="font-weight:bold;">貸出中状態</span>
            <div style="height:150px; display:flex">
              <div v-for="(photo,index) in photos" :key="index" class="img-area">
                <img @click="openModal(photo.no, 1, photo.status)" class="photo" :src="'data:image/png;base64,'+photo.data" height="80" width="80">
                <span>{{ photo.statusName }}</span>
              </div>
            </div>
            <span style="font-weight:bold">依頼状態</span>
            <div style="height:150px; display:flex">
              <div v-for="(photo,index) in iraiPhotos" :key="index" class="img-area">
                <img @click="openModal(photo.no, 1, photo.status)" class="photo" :src="'data:image/png;base64,'+photo.data" height="80" width="80">
                <span>{{ photo.statusName }}</span>
              </div>
            </div>
          </div>
          <div class="add-area">
            <input @change="takePhoto" id="camera" type="file" accept="image/*" capture="camera" style="display:none"/>
            <ion-button color="primary" @click="addPhoto" :disabled="iraiPhotos.length>=2">写真を追加</ion-button>
          </div>
          <div class="back-btn">
              <ion-button color="warning" @click="clickBack()">戻る</ion-button>
          </div>
        </ion-card-content>
      </ion-card>
    <ion-modal
      :is-open="isOpenRef"
      css-class="my-setting-class"
      :value="setOpen(false)"
      show-backdrop="true"
    >
      <ImageModal v-if="resetFlg" v-on:close-modal="closeModal" :memberId="memberId" :rentNo="rentNo" :contCd="contCd" :imageNo="imageNo" :mode="modalMode" :delFlg="delFlg" :status="photoStatus"></ImageModal>
    </ion-modal>
    </ion-content>
  </ion-page>
</template>

<style scoped>
.photo-area{
  margin-top:20px;
}
.photo {
  margin:2px;
}
.img-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-area {
  margin-top: 20px;
}
.radio-label {
  margin-left: 2px;
  margin-right: 5px;
}
.back-btn{
  margin-top:30px;
}
</style>

<script>
import { IonContent, IonPage, IonCardTitle, IonButton, IonCard, IonCardContent, IonModal, modalController, loadingController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import ImageModal from '../components/ImageModal.vue';

export default defineComponent({
  name: 'Photos',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonButton,
    IonCard,
    IonCardContent,
    ImageModal,
    IonModal,
  },
  setup() {
    // ダイアログ表示制御
    const isOpenRef = ref(false);
    const setOpen = (state) => isOpenRef.value = state;
    return { isOpenRef, setOpen }
  },
  data(){
      return {
        memberId:'',
        rentNo:'',
        contCd:'',
        photo: {}, 
        photos: [],
        contPhotos: [],
        iraiPhotos: [],
        imageNo: 0,
        modalMode: 0,
        status: '0',
        photoStatus: '',
        resetFlg: true,
        delFlg: false,
      };
  },
  computed: {
    fixState: function () {
      if(this.$store.state.procType == this.$store.state.PHOTOS){
        return false;
      }else{
        return true;
      }
    }
  },
  ionViewDidEnter(){
    this.init();
  },
  methods:{
    async init(){
      
      this.memberId = this.$store.state.memberId;
      this.rentNo = this.$store.state.rentNo;
      this.contCd = this.$store.state.contCd;
      this.status = this.$store.state.procType;
      
      // 写真データ取得
      await this.getPhotos();
    },
    getPhotos(){
      const postData = {
        memberId: this.memberId,
        rentNo: this.rentNo,
        contCd: this.contCd,
      }

      this.axios.post('/genba/get-photos', postData).then((res)=> {
        if(res.data.result === 0){
          this.photos = res.data.photos;
          this.contPhotos = res.data.cont_photos;
          this.iraiPhotos = res.data.irai_photos;
          console.log(this.iraiPhotos)
        }
      })
    },
    addPhoto(){
      const file = document.getElementById('camera');
      file.click();
    },
    async takePhoto(e){

      if(e.target.value == '')return;

      // loading
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: '読み込み中 ...',
          duration: 5000,
        });
        
      await loading.present();

      const postData = new FormData();
      postData.append('memberId', this.memberId);
      postData.append('rentNo', this.rentNo);
      postData.append('status', this.status);
      postData.append('photo', e.target.files[0]);

      this.axios.post('/genba/add-photo', postData).then((res)=>{
        loading.dismiss();
        if(res.data.result == 0){
          this.getPhotos();
        }
      })
    },
    openModal(no, mode, status=''){
      this.imageNo = no;
      this.modalMode = mode;
      this.photoStatus = status;

      if(mode==1){
        this.delFlg = true;
      }else{
        this.delFlg = false;
      }

      this.setOpen(true);
    }, 
    // ダイアログを閉じる
    closeModal: function () {
      modalController.dismiss();
      this.resetFlg = false;
      this.$nextTick(() => {
        this.resetFlg = true;
      })
      this.getPhotos();
    },
    // 戻るボタン
    clickBack(){
      this.$router.back();
    }
  }
});
</script>
