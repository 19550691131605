<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title class="ion-text-center" size="large">{{ title }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <div class="conte">
              <ion-label position="floating">QRコード下部に記載されているコンテナ識別Noを入力してください。</ion-label>
              <ion-input class="inp-cont" v-model="inpCont" type="url"></ion-input>
          </div>
          <ion-button id="contnext" color="primary" expand="block" @click="readCont()">確認</ion-button>
          <ion-button id="contback" color="warning" @click="backPage">戻る</ion-button>
        </ion-card-content> 
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonCardTitle, IonLabel, IonInput, IonCard, IonCardContent, IonButton, alertController } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InpCont',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonLabel,
    IonInput,
    IonCard,
    IonCardContent,
    IonButton
  },
  data(){
      return {
        title:'',
        inpCont:'',
        tokuiCont:'',
        memberId:'',
        corpName:'',
        genbaName: '',
        address: '',
        startDate: '',
        biko: '',
        contCd: '',
        errMsg: '',
      };
  },
  ionViewDidEnter() {
    this.title = sessionStorage.getItem('title');
    this.inpCont = '';
  },
  methods: {
    // コンテナ情報取得
    readCont: async function () {
      await this.axios.get('genba-change', { params: {'cont_uq': this.inpCont, 'p_type': 2 } }).then(res => {
        if(res.data["result"] == "success"){

          this.memberId = res.data["member_id"];
          this.corpName = res.data["corp_name"];
          this.genbaName= res.data["genba_name"];
          this.address = res.data["address"];
          this.startDate= res.data["start_date"];
          this.contCd = res.data["cont_cd"];
          this.biko = res.data["biko"];

          this.$router.push({name: 'Kakutei', params: {
            memberId: this.memberId,
            corpName: this.corpName,
            genbaName: this.genbaName,
            address: this.address,
            startDate: this.startDate,
            contCd: this.contCd,
            biko: this.biko,
          }})
        }else{
          this.errMsg = res.data["msg"];
          this.presentAlert();
        }
      });
    },
    // エラーアラート表示 
    presentAlert: async function () {
      const alert = await alertController.create({
        header: 'エラー',
        message: this.errMsg,
        buttons: ['閉じる']
      });

      await alert.present();
    },

    // 戻る
    backPage: function(){
      this.$router.back();
    },
  },
});
</script>

<style scoped>
#video, #canvas {
  justify-content:center;
  margin:20px;
}
#canvas {
  display:none;
}
#qr {
  margin-left:10px;
}
.tokui {
  margin-left:20px;
}
#next, #contnext {
  margin-left:20px;
  margin-right:20px;
}
#back, #contback {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
.tokui-text {
  font-size:20px;
  text-decoration: underline;
  color: rgb(25, 148, 170);
}
.conte {
  margin:20px;
}
.inp-cont {
  border: solid 0.5px;
  border-color: #ddd;
  border-radius: 5px;
}
</style>