<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-card class="title">
        <ion-card-header color="secondary">
          <ion-card-title v-if="kokanFlg" class="ion-text-center" size="large">交換依頼</ion-card-title>
          <ion-card-title v-else class="ion-text-center" size="large">撤去依頼</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <!-- List Inset Lines -->
          <div class="content">
            <ion-item v-if="kokanFlg" class="ion-text-center">コンテナ交換依頼手続きを確定しました。</ion-item>
            <ion-item v-else class="ion-text-center">コンテナ撤去依頼手続きを確定しました。</ion-item>
          </div>
          <ion-button id="kakutei" color="primary" expand="block" @click="clickPhotos">写真を撮影</ion-button>
          <ion-button id="kakutei" color="primary" expand="block" @click="clickMenu">MENUに戻る</ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>

import { IonContent, IonPage, IonCardTitle, IonCard, IonButton, IonItem } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Kanryo',
  components: {
    IonContent,
    IonPage,
    IonCardTitle,
    IonCard,
    IonButton,
    IonItem
  },
  data(){
      return {
        kokanFlg:false,
      };
  },
  ionViewDidEnter(){
    if(sessionStorage.getItem('status') == 2) {
      this.kokanFlg = true;
    }else{
      this.kokanFlg = false;
    }
  },
  methods: {
    // QRコード読取画面へ戻る
    clickPhotos: function () {
      this.$router.push("/photos");
    },
    // QRコード読取画面へ戻る
    clickMenu: function () {
      this.$router.push("/menu");
    },
  }
});
</script>

<style scoped>
.content {
  margin-top: 10px;
}
#kakutei {
  margin:20px;
}
#back {
  margin:20px;
}
#title {
  width:100%;
  margin-left:auto;
  margin-right:auto;
}
</style>